import {
  Property,
  Transportation,
  RoadTransport,
  AreaInfoHandData,
  ParcelRecord,
} from '@/types/property'
import { RoadAccess } from '@/types/roadAccess'
import { ocrExecution } from '@/components/Ocr'
import { OcrFormat, OcrResultType, PurchaseResult, RentResult } from '@/types/OCR'
import { chooseFormat } from '@/components/Ocr/formats'
import { preCodes as prefectures } from '@/models/prefectureCodes'
import { cammedFormat, dataPrecisionSetup, formatToKanjiNumbers } from '@/utils/cammedFormat'
import { calculateTsubo } from '@/utils/area'
import { buildingTypeSaleOptions, buildingTypeRentOptions } from '@/models/marketDatum'
type OCRProperty = Omit<Property, 'area_info'> & { ocr_area_info: OCRAreaInfo }
type OCRAreaInfo = {
  city_planning: {
    merged_areas: Array<{
      area_of_use: {
        youto_chiiki: string
      }
    }>
  }
}

export class OCRResultConverter {
  readonly format: OcrFormat
  readonly scandata: ocrExecution['extractedItems']
  constructor(format: OcrFormat, response: ocrExecution['extractedItems']) {
    this.format = format
    this.scandata = response
    this.validate()
  }

  validate(): boolean {
    if (!('result' in this.scandata)) {
      throw new Error('Result is undefined in result JSON')
    }
    return true
  }

  getBlankFormatWithKeysDefined() {
    let base = structuredClone(chooseFormat(this.format))
    this.digAll(base)
    return base
  }

  digAll<T>(obj: T) {
    Object.keys(obj).map((key) => {
      if (typeof obj[key] === 'object' && obj[key]) {
        this.digAll(obj[key])
      } else {
        obj[key] = ''
      }
    })
  }
  setBlankAsDefault<T>(obj: T, key: string): T[keyof T] {
    return !!obj[key] ? obj[key] : ''
  }

  dig<T>(obj: T) {
    Object.keys(obj).map((key) => {
      if (typeof obj[key] === 'object' && obj[key]) {
        this.dig(obj[key])
      } else {
        obj[key] = this.setBlankAsDefault(obj, key)
      }
    })
  }

  digAndSet<T, R>(obj: T, result: R) {
    Object.keys(obj).map((key) => {
      if (typeof obj[key] === 'object' && obj[key] && result[key]) {
        this.digAndSet(obj[key], result[key])
      } else {
        obj[key] = !!result && result[key] !== 'UNKNOWN' ? result[key] : ''
      }
    })
  }

  async ensureAllFieldsExist(): Promise<OcrResultType> {
    const base = this.getBlankFormatWithKeysDefined()
    this.digAndSet(base.result, this.scandata.result)
    return base.result
  }

  async createProperty(isEdit): Promise<OCRProperty> {
    // ensure all fields defined in sample format exist
    const result = await this.ensureAllFieldsExist()
    const parcel_records = this.buildParcelRecords(result)
    const transportations = this.buildTransportations(result)
    const road_transports = this.buildRoadTransports(result)
    const road_accesses = this.buildRoadAccesss(result)
    const mergedAreas = this.buildMergeAreas(result)
    const area_info_hands = this.buildAreaInfoHands(result)

    const areaInfo: OCRAreaInfo = {
      city_planning: {
        merged_areas: mergedAreas,
      },
    }
    const areaInfoHands = area_info_hands

    let occupied_area
    if (result?.['建物']?.['専有面積']) {
      occupied_area = this.formatM3(result['建物']['専有面積'])
    } else if (result?.['建物']?.['延床面積']) {
      occupied_area = this.formatM3(result['建物']['延床面積'])
    } else {
      // assign nothing
    }

    const areaData = {
      areaM3: this.formatM3(result?.['土地']?.['地積公簿']),
      areaM3Tsubo: '',
      effectiveArea: this.formatM3(result?.['土地']?.['地積実測']),
      effectiveAreaTsubo: '',
      totalFloorArea: this.formatM3(result?.['建物']?.['延床面積']),
      totalFloorAreaTsubo: '',
      occupiedArea: occupied_area,
      occupiedAreaTsubo: '',
    }

    const caluculateAreaData = this.buildArea(areaData)

    const pref = await this.buildPrefecture(result)
    // gptからのレスポンスにはカラム自体が存在しない可能性もあるため、全てにオプショナルチェインをつける
    const property = {
      ...(isEdit
        ? {
            // 編集画面に存在する項目だが、物件概要書に記載がされない項目については、
            // コメントアウトする
            // total_expense_cost_purchase: '',
            // building_construction_cost:'',
            // selled_at:  '',
            // building_price: '',
            // total_expense_cost_price: '',
            // business_income: '',
            // land_price: '',
            // building_price:  '',
            purchase_price: this.formatEn(result?.['販売価格']) ?? '',
            tenancy_rate: this.formatYeild(result?.['稼働率']) ?? '',
            rent_per_tsubo: this.formatEn(result?.['賃料']?.['坪単価']) ?? '',
            total_rent: this.formatEn(result?.['賃料']?.['月割り総額賃料']) ?? '',
            prospective_noi: this.formatEn(result?.['賃料']?.['月割り想定NOI']) ?? '',
          }
        : {
            // 物件の新規登録画面のみ住居表示を読み取る
            prefecture: pref ?? '',
            city: result?.['住所']?.['市区町村'] ?? '',
            town: result?.['住所']?.['町名'] ?? '',
            chome: this.formatChome(result?.['住所']?.['丁目・その他'] ?? ''),
          }),
      chiban: result?.['地番'] ? result['地番'].join(',') : '',
      map_information: result?.['物件名'] ?? '',
      area_m3: caluculateAreaData['areaM3'],
      effective_area: caluculateAreaData['effectiveArea'],
      total_floor_area: caluculateAreaData['totalFloorArea'],
      occupied_area: caluculateAreaData['occupiedArea'],
      parcel_records: parcel_records,
      transportations: transportations,
      road_transports: road_transports,
      road_accesses: road_accesses,
      coverage_ratio: result?.['用途地域']?.['建蔽率']
        ? result['用途地域']['建蔽率'].join(',')
        : '',
      volume_rate: result?.['用途地域']?.['容積率'] ? result['用途地域']['容積率'].join(',') : '',
      number_of_units: result?.['建物']?.['総戸数'] ?? '',
      building_structure: result?.['建物']?.['構造'] ?? '',
      floors_above_ground: result?.['建物']?.['階数']?.['地上'] ?? '',
      floors_under_ground: result?.['建物']?.['階数']?.['地下'] ?? '',
      build_completed_at: result?.['建物']?.['築年月'] ?? '',
      certificate_of_inspection: result?.['検査済証'] ?? '',
      ocr_area_info: areaInfo,
      area_info_hands: areaInfoHands,
      number_of_buildings: this.extractNumber(result?.['棟数（区画）']) ?? '',
      current_rent: this.formatEn(result?.['賃料']?.['月割り現況賃料']) ?? '',
      property_type: result?.['物件種目'] ?? '',
      local_situation: result?.['現況'] ?? '',
      location_of_division:
        result?.['物件種目'] === '土地＋建物（区分）' && result?.['部屋番号']
          ? this.extractNumber(result?.['部屋番号'])
          : '',
      area_tsubo: caluculateAreaData['areaM3Tsubo'],
      effective_area_tsubo: caluculateAreaData['effectiveAreaTsubo'],
      occupied_area_tsubo: caluculateAreaData['occupiedAreaTsubo'],
      total_floor_area_tsubo: caluculateAreaData['totalFloorAreaTsubo'],
      // 概要書では、「価格」という表記が希望価格と販売価格を区別せず使用されている場合が多い
      // 記載されている価格が実際には希望価格であるにもかかわらず、販売価格として解釈されることがある多い
      // よって、希望価格の設定がない場合は、販売価格を設定する。
      suggested_total_price: this.formatEn(result?.['希望価格'] || result?.['販売価格']) ?? '',
    } as OCRProperty
    return property
  }

  async createMarketData(): Promise<any> {
    const result = await this.ensureAllFieldsExist()
    const transportations = this.buildTransportations(result)
    const road_accesses = this.buildRoadAccesss(result)
    const building_type = this.buildBuildingType(this.format, result)
    const custom_building_type = building_type === 'others_type' ? result['物件タイプ'] : undefined
    const floorType = this.buildFloorType(result)
    const areaData = {
      areaM3: this.formatM3(result?.['建物']?.['敷地面積']),
      areaM3Tsubo: '',
      effectiveArea: this.formatM3(result?.['建物']?.['有効敷地面積']),
      effectiveAreaTsubo: '',
      totalFloorArea: this.formatM3(result?.['建物']?.['延床面積']),
      totalFloorAreaTsubo: '',
      occupiedArea: this.formatM3(result?.['建物']?.['専有面積']),
      occupiedAreaTsubo: '',
    }

    const caluculateAreaData = this.buildArea(areaData)
    const pref = await this.buildPrefecture(result)

    if (this.format == 'purchase') {
      const r = result as PurchaseResult

      return {
        prefecture: pref ?? '',
        city: r?.['住所']?.['市区町村'] ?? '',
        town: r?.['住所']?.['町名'] ?? '',
        chome: r?.['住所']?.['丁目・その他'] ?? '',
        status: r?.['募集状態'] ?? '',
        transportations: transportations,
        road_accesses: road_accesses,
        publication_date: r?.['掲載日'] ?? '',
        contract_date: r?.['成約日'] ?? '',
        name: r?.['物件名'] ?? '',
        area_m3: caluculateAreaData['areaM3'],
        effective_area: caluculateAreaData['effectiveArea'],
        total_floor_area: caluculateAreaData['totalFloorArea'],
        occupied_area: caluculateAreaData['occupiedArea'],
        floors_above_ground: r?.['建物']?.['階数']?.['地上'] ?? '',
        floors_under_ground: r?.['建物']?.['階数']?.['地下'] ?? '',
        number_of_units: r?.['建物']?.['総戸数'] ?? '',
        floor_plan: r?.['間取り'] ?? '',
        building_structure: r?.['建物']?.['構造'] ?? '',
        build_date: r?.['建物']?.['竣工年月'] ?? '',
        orientation: r?.['向き'].replace('向き', ''),
        indoor_condition: r?.['現況'] ?? '',
        elevator: r?.['エレベータ'] ?? '',
        auto_lock: r?.['オートロック'] ?? '',
        building_price: this.formatEn(r?.['成約']?.['成約価格']) ?? '',
        prospective_yield: this.formatEn(r?.['成約']?.['成約表面利回り']) ?? '',
        building_type: building_type,
        custom_building_type: custom_building_type || undefined,
        contractor: r?.['施工会社'] ?? '',
        floor_type: floorType,
        floor: r?.['所在階']?.['階数'] ?? '',
        current_rent: this.formatEn(r?.['賃料']?.['月割り現況賃料']),
        full_prospective_rent: this.formatEn(r?.['賃料']?.['月割り満室想定賃料']),
        sale_price: this.formatEn(r?.['売出']?.['売出価格']),
        sale_prospective_yield: this.formatYeild(r?.['売出']?.['売出表面利回り']),
        location_of_division:
          result?.['物件種目'] === '土地＋建物（区分）' && result?.['部屋番号']
            ? this.extractNumber(result?.['部屋番号'])
            : '',
        area_m3_tsubo: caluculateAreaData['areaM3Tsubo'],
        effective_area_tsubo: caluculateAreaData['effectiveAreaTsubo'],
        occupied_area_tsubo: caluculateAreaData['occupiedAreaTsubo'],
        total_floor_area_tsubo: caluculateAreaData['totalFloorAreaTsubo'],
        property_type: result?.['物件種目'] ?? '',
      }
    } else if (this.format == 'rent') {
      const r = result as RentResult

      return {
        prefecture: pref ?? '',
        city: result?.['住所']?.['市区町村'] ?? '',
        town: result?.['住所']?.['町名'] ?? '',
        chome: result?.['住所']?.['丁目・その他'] ?? '',
        status: r?.['募集状態'] ?? '',
        transportations: transportations,
        publication_date: r?.['掲載日'] ?? '',
        contract_date: r?.['成約日'] ?? '',
        name: r?.['物件名'] ?? '',
        //area_m3: caluculateAreaData['areaM3'],
        //effective_area: caluculateAreaData['effectiveArea'],
        total_floor_area: caluculateAreaData['totalFloorArea'],
        occupied_area: caluculateAreaData['occupiedArea'],
        floors_above_ground: r?.['建物']?.['階数']?.['地上'] ?? '',
        floors_under_ground: r?.['建物']?.['階数']?.['地下'] ?? '',
        //number_of_units: r["建物"]["総戸数"],
        location_of_devision: r?.['賃料']?.['号室'] ?? '',
        floor_plan: r?.['間取り'] ?? '',
        building_structure: r?.['建物']?.['構造'] ?? '',
        build_date: r?.['建物']?.['竣工年月'] ?? '',
        rent: r?.['賃料']?.['金額'] ?? '',
        maintenance_fee: r?.['賃料']?.['管理費'] ?? '',
        security_deposit: r?.['賃料']?.['敷金'] ?? '',
        key_money: r?.['賃料']?.['礼金'] ?? '',
        orientation: r?.['向き'].replace('向き', ''),
        indoor_condition: r?.['現況'] ?? '',
        //room_type
        elevator: r?.['エレベータ'] ?? '',
        auto_lock: r?.['オートロック'] ?? '',
        balcony: r?.['バルコニー'] ?? '',
        free_internet: r?.['ネット無料'] ?? '',
        furniture_and_appliances: r?.['家具家電'] ?? '',
        building_price: r?.['販売価格'] ?? '',
        prospective_yield: r?.['表面利回り'] ?? '',
        building_type: building_type,
        custom_building_type: custom_building_type || undefined,
        contractor: r?.['施工会社'] ?? '',
        floor_type: floorType,
        floor: r?.['所在階']?.['階数'] ?? '',
        location_of_division:
          result?.['物件種目'] === '土地＋建物（区分）' && result?.['部屋番号']
            ? this.extractNumber(result?.['部屋番号'])
            : '',
        // area_m3_tsubo: caluculateAreaData['areaM3Tsubo'],
        // effective_area_tsubo: caluculateAreaData['effectiveAreaTsubo'],
        occupied_area_tsubo: caluculateAreaData['occupiedAreaTsubo'],
        total_floor_area_tsubo: caluculateAreaData['totalFloorAreaTsubo'],
        property_type: result?.['物件種目'] ?? '',
      }
    }
  }

  async createData(isEdit): Promise<OcrResultType> {
    return this.format == 'acquisition' ? this.createProperty(isEdit) : this.createMarketData()
  }

  private buildBuildingType(format, result) {
    if (!result?.['物件タイプ']) {
      return undefined
    }
    // 事例を判定
    const targetBuildTypeOptions =
      format == 'rent' ? buildingTypeRentOptions : buildingTypeSaleOptions
    const targetBuildingTypes = []
    // OCRで読み込まれた文字列が、選択項目に存在する場合はその項目をセットする
    for (const element of targetBuildTypeOptions) {
      if (result?.['物件タイプ'].includes(element.text)) {
        targetBuildingTypes.push(element.value)
      }
    }
    // lengthが0か2以上の場合はothers_type(自由入力）に丸める
    const targetBuildingType = targetBuildingTypes.length !== 1 ? undefined : targetBuildingTypes[0]
    return targetBuildingType ? targetBuildingType : 'others_type'
  }
  private buildFloorType(result) {
    switch (result?.['所在階']?.['階層']) {
      case '地下':
        return 'under_ground'
      default:
        return 'above_ground'
    }
  }

  private buildParcelRecords(result): ParcelRecord[] {
    if (this.isNullOrAllValuesEmpty(result?.['地目'])) {
      // 空配列を返すとフロント側に「交通」フォームが表示されない仕様になっているので、空文字が入っているオブジェクトを返す
      const parcel_recordsEmpty: ParcelRecord[] = [
        {
          id: '',
          parcel_number: '',
          parcel_use: '',
          parcel_rights: '',
        },
      ]
      return parcel_recordsEmpty
    }
    const parcel_records: ParcelRecord[] = result['地目']?.map((t) => {
      return {
        parcel_number: t?.['地番'] ?? '',
        parcel_use: t?.['地目種類'] ?? '',
        parcel_rights: t?.['権利'] ?? '',
      }
    })

    return parcel_records.filter((obj) => Object.values(obj).some((value) => value !== ''))
  }

  private buildTransportations(result): Transportation[] {
    if (this.isNullOrAllValuesEmpty(result?.['最寄り'])) {
      // 空配列を返すとフロント側に「交通」フォームが表示されない仕様になっているので、空文字が入っているオブジェクトを返す
      const transportationsEmpty: Transportation[] = [
        {
          id: '',
          bus: '',
          minutes_on_foot: '',
          nearest_station: '',
          transportation: '',
          walking: '',
        },
      ]
      return transportationsEmpty
    }
    const transportations: Transportation[] = result['最寄り']?.map((t) => {
      return {
        transportation: t?.['路線名'] ?? '',
        nearest_station: t?.['駅名'] ?? '',
        walking: t?.['徒歩時間'] ? this.extractNumber(t['徒歩時間']) : '',
        bus: t?.['バス時間'] ?? '',
        minutes_on_foot: t?.['バス下車徒歩'] ?? '',
      }
    })

    return transportations.filter((obj) => Object.values(obj).some((value) => value !== ''))
  }

  private buildRoadTransports(result): RoadTransport[] {
    if (this.isNullOrAllValuesEmpty(result?.['IC'])) {
      // 空配列を返すとフロント側に「交通」フォームが表示されない仕様になっているので、空文字が入っているオブジェクトを返す
      const road_transportsEmpty: RoadTransport[] = [
        {
          id: '',
          road_name: '',
          ic_name: '',
          distance_to_destination: '',
          estimated_time_minutes: '',
        },
      ]
      return road_transportsEmpty
    }
    const road_transports: RoadTransport[] = result['IC']?.map((t) => {
      return {
        road_name: t?.['道路名'] ?? '',
        ic_name: t?.['IC名'] ?? '',
        distance_to_destination: t?.['車距離'] ? this.extractNumber(t['車距離']) : '',
        estimated_time_minutes: t?.['車時間'] ? this.extractNumber(t['車時間']) : '',
      }
    })

    return road_transports.filter((obj) => Object.values(obj).some((value) => value !== ''))
  }

  private buildRoadAccesss(result): RoadAccess[] {
    console.log('result', result)
    if (this.isNullOrAllValuesEmpty(result?.['道路'])) {
      // 空配列を返すとフロント側に「道路」フォームが表示されない仕様になっているので、空文字が入っているオブジェクトを返す
      const roadAccessEmpty: RoadAccess[] = [
        {
          direction: '',
          width: '',
          road_type: '',
          frontage: '',
          building_standards_law_articles: '',
        },
      ]
      return roadAccessEmpty
    }
    const road_accesses: RoadAccess[] = result['道路']?.map((t) => {
      return {
        direction: t?.['方位'] ?? '',
        width: t?.['幅員'] ? this.extractNumber(t['幅員']) : '',
        frontage: t?.['間口'] ? this.extractNumber(t['間口']) : '',
        road_type: t?.['道路タイプ'] ?? '',
        building_standards_law_articles: t?.['条項'] ?? '',
      }
    })
    return road_accesses.filter((obj) => Object.values(obj).some((value) => value !== ''))
  }
  private buildMergeAreas(result): [] {
    if (this.isNullOrAllValuesEmpty(result?.['用途地域'])) {
      const mergedAreasEmpty: [] = [
        {
          area_of_use: {
            youto_chiiki: '',
            floor_area_ratio: '',
            building_coverage_ratio: '',
          },
        },
      ]
      return mergedAreasEmpty
    }
    const mergedAreas = result['用途地域']?.map((v) => {
      return {
        area_of_use: {
          youto_chiiki: v?.['種類'] ?? '',
          floor_area_ratio: v?.['容積率'] ? this.formatYeild(v?.['容積率']) : '',
          building_coverage_ratio: v?.['建蔽率'] ? this.formatYeild(v?.['建蔽率']) : '',
        },
      }
    })
    return mergedAreas.filter((obj) => Object.values(obj).some((value) => value !== ''))
  }

  private buildAreaInfoHands(result): [] {
    if (this.isNullOrAllValuesEmpty(result?.['用途地域'])) {
      const areaInfoHandsEmpty: [] = [
        {
          zoning_area: '',
          youto_chiiki_hand: '',
          bouka_chiiki_hand: '',
          koudo_chiku_hand: '',
          shadow_area_hand: '',
          floor_area_ratio_hand: '',
          building_coverage_ratio_hand: '',
          other_info: '',
        },
      ]
      return areaInfoHandsEmpty
    }
    const areaInfoHands = result['用途地域']?.map((v) => {
      return {
        zoning_area: v?.['区域区分'] ?? '',
        youto_chiiki_hand: v?.['種類'] ?? '',
        bouka_chiiki_hand: v?.['防火地域'] ?? '',
        koudo_chiku_hand: v?.['高度地区'] ? this.formatKanjiNumbers(v?.['高度地区']) : '',
        shadow_area_hand: v?.['日影規制'] ?? '',
        floor_area_ratio_hand: v?.['容積率'] ? this.formatYeild(v?.['容積率']) : '',
        building_coverage_ratio_hand: v?.['建蔽率'] ? this.formatYeild(v?.['建蔽率']) : '',
        other_info: v?.['その他'] ?? '',
      }
    })
    return areaInfoHands.filter((obj) => Object.values(obj).some((value) => value !== ''))
  }

  private buildArea(areaData) {
    // 各々の面積と坪の計算(物件登録画面の計算方法に準拠)
    const areaM3 = areaData['areaM3'] ? dataPrecisionSetup(areaData['areaM3'].toString(), 12, 2) : 0

    const effectiveArea = areaData['effectiveArea']
      ? dataPrecisionSetup(areaData['effectiveArea'].toString(), 12, 2)
      : 0
    const occupiedArea = areaData['occupiedArea']
      ? dataPrecisionSetup(areaData['occupiedArea'].toString(), 12, 2)
      : 0
    const totalFloorArea = areaData['totalFloorArea']
      ? dataPrecisionSetup(areaData['totalFloorArea'].toString(), 12, 2)
      : 0

    //敷地面積の計算
    areaData['areaM3'] = areaM3 ? cammedFormat(areaM3, 2) : ''
    areaData['areaM3Tsubo'] = areaM3 ? calculateTsubo(areaM3) : ''

    //有効敷地面積の計算
    // 有効敷地面積がないもしくは0の時は敷地面積を入れる
    if (!effectiveArea) {
      areaData['effectiveArea'] = areaM3 ? cammedFormat(areaM3, 2) : ''
      areaData['effectiveAreaTsubo'] = areaM3 ? calculateTsubo(areaM3) : ''
    } else {
      areaData['effectiveArea'] = cammedFormat(effectiveArea, 2)
      areaData['effectiveAreaTsubo'] = calculateTsubo(effectiveArea)
    }

    //延床面積の計算
    areaData['totalFloorArea'] = areaM3 ? cammedFormat(totalFloorArea, 2) : ''
    areaData['totalFloorAreaTsubo'] = areaM3 ? calculateTsubo(totalFloorArea) : ''

    // 建物賃貸可能面積（専有面積）の計算
    // 建物賃貸可能面積（専有面積）がない場合は延床面積を入れる
    if (!occupiedArea) {
      areaData['occupiedArea'] = totalFloorArea ? cammedFormat(totalFloorArea, 2) : ''
      areaData['occupiedAreaTsubo'] = totalFloorArea ? calculateTsubo(totalFloorArea) : ''
    } else {
      areaData['occupiedArea'] = cammedFormat(occupiedArea, 2) ?? ''
      areaData['occupiedAreaTsubo'] = calculateTsubo(occupiedArea) ?? ''
    }
    // '0.00'は全てから文字に変更
    for (const key in areaData) {
      if (areaData.hasOwnProperty(key)) {
        if (areaData[key] === '0.00') {
          areaData[key] = ''
        }
      }
    }
    return areaData
  }

  private async buildPrefecture(result) {
    // 読み取り値が不完全でも該当する文字がふくまれれば極力都道府県を取得できるように(東京しか読み取れなければ東京都を取得)
    const pref = Object.keys(prefectures).filter((pref) => {
      return pref.includes(result?.['住所']?.['都道府県'])
    })
    if (pref.length === 1) {
      return pref?.[0]
    }
    // 市区町村が取得できていれば、町名から都道府県を取得して保管する。
    const prefPrediction =
      pref.length !== 1 && result?.['住所']?.['市区町村']
        ? await this.fetchPrefectureFromCity(result?.['住所']?.['市区町村'])
        : ''
    return pref.length === 1 ? pref?.[0] : prefPrediction
  }

  private fetchPrefectureFromCity = async (childCity) => {
    try {
      const response = await fetch('/japanese-addresses/ja.json')
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      const data = await response.json()
      return Object.keys(data).filter((prefectureName) => data[prefectureName].includes(childCity))
    } catch (error) {
      console.error('Error:', error)
      return ''
    }
  }

  private isNullOrAllValuesEmpty(arrayObj: []): boolean {
    // ハッシュの配列の値が全て空の場合もしくは要素自体がundefindの場合はtrue
    if (arrayObj === undefined || arrayObj === '') {
      return true
    }
    return arrayObj?.every((obj) => {
      if (obj === undefined) {
        return true
      }
      return Object.values(obj).every(
        (value) => value === '' || value === undefined || value === null
      )
    })
  }

  private formatM3(str: string) {
    if (!str) return ''
    return cammedFormat(dataPrecisionSetup(str, 12, 2), 2)
  }

  private formatEn(str: string) {
    if (!str) return ''
    return cammedFormat(dataPrecisionSetup(str, 12, 0), 0)
  }

  private formatYeild(str: string) {
    if (!str) return ''
    return dataPrecisionSetup(str, 12, 2).toFixed(2)
  }

  private formatChome = (value: string) => {
    const match = value.match(/[0-9\-０-９ーー―‐ー−－]/g)
    value = match === null ? '' : match.join('')
    value = value.replace(/[０-９ーー―‐ー−－]/g, (c) =>
      c.match(/[ーー―‐ー−－]/) ? '-' : String.fromCharCode(c.charCodeAt(0) - 0xfee0)
    )
    return value
  }

  private extractNumber = (input: string | number) => {
    if (!input) return ''
    const inputStr = String(input) // 明示的に文字列に変換
    const match = inputStr.match(/-?\d+(\.\d+)?/)
    return match ? match[0] : ''
  }

  private formatKanjiNumbers = (input: string | number) => {
    if (!input) return ''
    const inputStr = String(input) // 明示的に文字列に変換
    // プロンプトで全角英数字の入力を完全に防ぐことが難しいため、機械的に変換する
    return formatToKanjiNumbers(inputStr)
  }

  // unused
  ensureRequiredFieldsDefined<T>(input: T): T {
    this.digUndefined(input)
    return input
  }
  // unused
  digUndefined<T>(obj: T) {
    Object.keys(obj).map((key) => {
      if (typeof obj[key] === 'object' && obj[key]) {
        this.digUndefined(obj[key])
      } else {
        obj[key] = this.setBlankAsDefault(obj, key)
      }
    })
  }
}
