import * as React from 'react'

import Base from '@/components/Layout/Base'
import { Table } from '@/components/Table'
import { tagColorCodes } from '@/models/tagColorCodes'
import type { Property, ResultContents } from '@/types/property'
import type { User } from '@/types/user'

import { AnchorButton, Button } from '@/components/Button'
import { ConfirmDialog } from '@/components/ConfirmDialog'
import { GoogleMapViewer, MapHandles } from '@/components/GoogleMapViewer/Properties'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import MapIcon from '@material-ui/icons/Map'
import { useJsApiLoader } from '@react-google-maps/api'
import { LoadingOverlay } from '@/components/Loading/LoadingOverlay'
import { Breadcrumb } from '@/components/Breadcrumb'
import { ChildOption, Option } from '@/components/DropDown/Index'
import { Row, Td, Th } from '@/components/Page/Properties/Detail/Form'
import {
  RowAreaInfo,
  TdAreaInfo,
  TdAreaInfoData,
} from '@/components/Page/Properties/Detail/FormAreaInfo'
import PublicFileDetails from '@/components/Page/Properties/Detail/PublicFileDetails'
import PropertyDocuments from '@/components/Page/Properties/Detail/PropertyDocuments'
import PropertyTouki from '@/components/Page/Properties/Detail/PropertyTouki'
import PropetyAttachments from '@/components/Page/Properties/Detail/PropetyAttachments'
import PropetyDetails from '@/components/Page/Properties/Detail/PropetyDetails'
import PropetyMarketDatumReport from '@/components/Page/Properties/Detail/PropetyMarketDatumReport'
import PropetyProposals from '@/components/Page/Properties/Detail/PropetyProposals'
import PropetySurroundings from '@/components/Page/Properties/Detail/PropetySurroundings'
import PropetyVolume from '@/components/Page/Properties/Detail/PropetyVolume'
import TimeFrame from '@/components/Page/Properties/Detail/TimeFrame'
import { unit_m2 } from '../VolumeCheckRequests/volumeUnitFormat'

import { Modal } from '@/components/Modal'
import KintoneIcon from '@/images/icons/kintone.svg'
import { Pagy } from '@/types/pagy'
import { Tag } from '@/types/tag'
import { cammedFormat } from '@/utils/cammedFormat'
import { detectLargeScreen } from '@/utils/screen'
import { DisplayItem } from '@/types/displayItem'

type Props = {
  google_maps_api_key: string
  polygons_api_base_url: string
  current_user: User
  property: Property
  source_display: { [key: number]: boolean }
  is_show_detail: boolean
  is_enable_edit_detail?: boolean
  is_enable_attachments: boolean
  is_enable_public_files: boolean
  is_enable_property_documents: boolean
  is_enable_surroundings: boolean
  is_enable_market_datum_report: boolean
  authenticity_token: string
  attached_files: File[]
  excel_file?: File
  type: PageType
  public_file_details?: { [key: string]: string }
  property_documents?: { [key: string]: string }
  hazard_links?: { [key: string]: string }
  show_kintone_connect: boolean
  disable_kintone_connect: boolean
  error_messages: string[]
  market_datum_report_layouts?: any
  market_datum_report_column_names?: string[]
  road_access_display: string[]
  prefecture_options: Option[]
  transportation_options: Option[]
  area_info_hands: AreaInfoHandData[]
  station_options: ChildOption[]
  floor_plan_options: any[]
  contractor_options: any[]
  room_type_options: any[]
  result_contents: ResultContents[]
  pagy: Pagy
  display_items: DisplayItem[]
}

type File = {
  id: number
  name: string
  created_at: string
  url: string
  preview_url: string
  download_url: string
  key: string
}

type PageType =
  | 'proposals'
  | 'property_details'
  | 'attachments'
  | 'public_files'
  | 'property_documents'
  | 'surroundings'
  | 'market_datum_report'
  | 'touki'
  | 'volume_check'

type LatLng = number[]

export type AreaInfoHandData = {
  id?: number | string
  index?: number
  zoning_area: number | string
  youto_chiiki_hand: number | string
  bouka_chiiki_hand: number | string
  koudo_chiku_hand: number | string
  shadow_area_hand: number | string
  building_coverage_ratio_hand: number
  floor_area_ratio_hand: number
  other_info: string
}

const pageTitle = {
  proposals: '入手情報',
  property_details: '物件詳細',
  attachments: '関連資料',
  property_documents: '帳票作成',
  surroundings: '周辺情報',
  public_files: '公開ページ',
  market_datum_report: '帳票作成',
  touki: '登記情報',
  volume_check: 'Volume Check',
}

const PropertiesDetailPage: React.FC<Props> = ({
  google_maps_api_key,
  polygons_api_base_url,
  current_user,
  property,
  source_display,
  is_show_detail = false,
  is_enable_edit_detail = false,
  is_enable_attachments = false,
  is_enable_public_files = false,
  is_enable_property_documents = false,
  is_enable_surroundings = false,
  is_enable_market_datum_report = false,
  attached_files,
  excel_file,
  authenticity_token,
  type,
  public_file_details,
  property_documents,
  hazard_links,
  show_kintone_connect,
  disable_kintone_connect,
  error_messages,
  market_datum_report_layouts,
  market_datum_report_column_names,
  road_access_display,
  prefecture_options,
  transportation_options,
  area_info_hands,
  station_options,
  floor_plan_options,
  contractor_options,
  room_type_options,
  result_contents,
  pagy,
  display_items,
}) => {
  const title = pageTitle[type] ?? '入手情報'
  const [isLoading, setIsLoading] = React.useState(false)

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: google_maps_api_key,
    libraries: ['geometry', 'drawing', 'places'],
  })

  const [pageType, setPageType] = React.useState<PageType>('proposals')

  const colorIndex = property?.tag?.color_before_type_cast
    ? property.tag.color_before_type_cast - 1
    : 0
  const tagClassName = `text-white ${tagColorCodes[colorIndex].bg}`

  const csrfToken: HTMLMetaElement = document.head.querySelector('meta[name="csrf-token"]')

  // ref を作成しスクロールさせたい場所にある Element にセット
  const ref = React.createRef<HTMLDivElement>()

  const mapRef = React.useRef<MapHandles>(null)
  const [onLoadComplete, setOnLoadComplete] = React.useState<boolean>(false)

  const [wideAreaZoom, setWideAreaZoom] = React.useState<number>('')
  const [narrowAreaZoom, setNarrowAreaZoom] = React.useState<number>('')
  const [showYoutoInfo, setShowYoutoInfo] = React.useState<boolean>(false)
  const [youtoInfo, setYoutoInfo] = React.useState<any>({})
  const [downloadDisabled, setDownloadDisabled] = React.useState<boolean>(false)
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false)

  const tags = current_user.company?.tags || []
  const [tagChangeModal, setTagChangeModal] = React.useState(false)
  const tagNullColor = 'bg-gray-400'
  const [tagColor, setTagColor] = React.useState(tagNullColor)
  const [tag, setTag] = React.useState<Tag | undefined>()
  const tagChangeFormRef = React.useRef<HTMLFormElement>(null)

  const is_enable_volume_check =
    current_user.role === 'system_admin' ||
    (current_user.company.pricing_type === 'enterprise' && current_user.is_volume_check)

  React.useEffect(() => {
    const tagColor =
      tags.find((t) => t.name === property?.['tag']?.name)?.color_before_type_cast - 1
    setTagColor((property?.['tag']?.name && tagColorCodes[tagColor].bg) || tagNullColor)
  }, [property?.['tag']?.name])

  const changeTagCallback = React.useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const tagId = Number(event.target.value)
      const tag = tags.find((t) => t.id === tagId)
      setTagColor((tag && tagColorCodes[tag.color_before_type_cast - 1]?.bg) || tagNullColor)
      setTag(tag)
      setTagChangeModal(true)
    },
    [tagColor]
  )

  const onTagChangeModalClose = React.useCallback(() => {
    setTagChangeModal(false)
    const tag = tags.find((t) => t.name === property?.['tag']?.name)
    const tagColor = tag?.color_before_type_cast - 1
    setTagColor((property?.['tag']?.name && tagColor && tagColorCodes[tagColor].bg) || tagNullColor)
    setTag(tag)
  }, [tagChangeModal])

  const onCloseDialog = (result) => {
    if (!result) {
      setDialogOpen(false)
      return
    }

    fetch(`/api/properties/${property.hashid}/kintone_connect`, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': authenticity_token,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json['status'] === 'OK') {
          const message = [json['message']].flat().join('\n')
          alert(message)
        } else {
          const message = [json['message']].flat().join('\n')
          alert(message || 'エラーが発生しました')
        }
      })
      .catch((err) => {
        alert('エラーが発生しました')
      })
      .finally(() => {})

    setDialogOpen(false)
  }

  React.useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)

    let timeout = null
    if (searchParams.get('tab')) {
      const page = searchParams.get('tab') as PageType
      setPageType(page)
      const refCurrent = ref.current
      timeout = setTimeout(() => {
        refCurrent.scrollIntoView({ behavior: 'smooth' })
      }, 500)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  let shape: LatLng[]

  if (property?.area_info && 'borders' in JSON.parse(property?.area_info)) {
    const area_info = JSON.parse(property.area_info)

    shape = area_info.borders
      .sort((a, b) => a.id - b.id)
      .map((border) => ({ lat: border.to.lat, lng: border.to.lng }))
  }

  const loadStart = () => {
    setIsLoading(true)
    const form = document.querySelector('form')
    if (form) {
      const formData = new FormData(form)
      fetch(form.action, {
        method: 'POST',
        body: formData,
      })
        .then((response) => {
          if (response.ok) {
            setIsLoading(false)
          } else {
            throw new Error('PDFダウンロードに失敗しました')
          }
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }
  
  const [isMapVisible, setIsMapVisible] = React.useState(true)
  const [forceMarketDatumReportMap, setForceMarketDatumReportMap] = React.useState(false)
  const [, setTick] = React.useState(0);

  // 状態変更時に強制再描画
  React.useEffect(() => {
    setTick((v) => v + 1);
  }, [forceMarketDatumReportMap]);

  return (
    <Base
      current_user={current_user}
      title={title}
      submenuCategory="properties"
      city={property.prefecture + property.city}
      breadcrumb={<Breadcrumb link_texts={['物件管理', title]} />}
    >
      <div className="flex justify-between items-end">
        <LoadingOverlay isOpen={isLoading} text="Download" />

        <div className="flex gap-x-3">
          {property.ongoing_proposal && (
            <Status outlined className="text-primary border-primary">
              検討中
            </Status>
          )}

          {is_enable_edit_detail && (
            <select
              name="property[tag_id]"
              onChange={changeTagCallback}
              className={[
                'empty:hidden inline-flex text-sm font-bold ml-2 px-3 py-[4px] rounded-full border-[2px] border-transparent text-white',
                tagColor,
              ].join(' ')}
              value={tag?.id}
            >
              <option value="">タグ未設定</option>
              {tags.map((t) => (
                <option
                  key={t.id}
                  value={t.id}
                  className={tagColorCodes[t.id]?.bg}
                  selected={property?.['tag']?.name == t.name}
                >
                  {t.name}
                </option>
              ))}
            </select>
          )}
          {!is_enable_edit_detail && (
            <span
              className={[
                'my-2 empty:hidden inline-flex text-sm font-bold ml-2 px-3 py-[1px] rounded-xl border-[2px] border-transparent text-white',
                tagColor,
              ].join(' ')}
            >
              {property?.['tag']?.name ? property?.['tag']?.name : 'タグ未設定'}
            </span>
          )}
        </div>
        <div className="flex gap-x-3">
          {type === 'property_details' && show_kintone_connect && (
            <Button
              className="text-primary-font mt-2"
              prefix={<img src={KintoneIcon} className="h-[20px]" />}
              outline
              size="small"
              variant="primary"
              disabled={disable_kintone_connect}
              onClick={() => setDialogOpen(true)}
            >
              <span className="hidden md:inline">kintone連携</span>
            </Button>
          )}

          <form action={`/properties/${property.hashid}/pdf`} method="post">
            <input type="hidden" name="authenticity_token" value={authenticity_token} />
            <input type="hidden" name="wide_area_zoom" value={wideAreaZoom} />
            <input type="hidden" name="narrow_area_zoom" value={narrowAreaZoom} />
            <input type="hidden" name="show_youto_info" value={showYoutoInfo} />
            <input type="hidden" name="youto_info" value={JSON.stringify(youtoInfo)} />

            <Button
              className="text-primary-font mt-2"
              prefix={<CloudDownloadIcon fontSize="small" />}
              outline
              size="small"
              variant="primary"
              disabled={downloadDisabled}
              onClick={loadStart}
            >
              <span className="hidden md:inline">PDFダウンロード</span>
            </Button>
          </form>
        </div>
      </div>
      <div className="mt-2">
        <Table className="border-t border-b border-[#3885B0]" tag="div">
          <div>
            <Row label={<Th left={true}>管理コード</Th>}>
              <Td>{property?.management_code}</Td>
            </Row>
            <Row label={<Th left={true}>案件名</Th>}>
              <Td>{property.name}</Td>
            </Row>
            <Row label={<Th left={true}>案件担当者</Th>}>
              <Td>
                {property.user?.name}
                {property.user?.department && ` / ${property.user.department.name}`}
              </Td>
            </Row>
            <Row label={<Th left={true}>所在地（住居表示）</Th>}>
              <Td>
                {property.prefecture} {property.city} {property.town} {property.chome}
              </Td>
            </Row>
            <Row label={<Th left={true}>丁目以降（地番）</Th>}>
              <Td>{property.chiban}</Td>
            </Row>
            <Row label={<Th left={true}>地図情報（建物名）</Th>}>
              <Td>{property.map_information}</Td>
            </Row>
            <Row label={<Th left={true}>物件種目</Th>}>
              <Td>{property.property_type}</Td>
            </Row>
            <Row label={<Th left={true}>地目・権利</Th>}>
              <Td>
                {property.parcel_records_display.map((parcel_record, index) => (
                  <div key={index} className={['w-full', index > 0 && 'mt-2'].join(' ')}>
                    {parcel_record}
                  </div>
                ))}
              </Td>
            </Row>
            <div className="flex flex-wrap">
              <Row
                className="w-full md:w-1/2"
                label={
                  <Th left={true} column={2}>
                    敷地面積
                  </Th>
                }
              >
                <Td column={2}>
                  {`${cammedFormat(Number(property.area_m3 ?? 0), 2)}㎡ ( ${cammedFormat(
                    Number(property.area_tsubo ?? 0),
                    2
                  )}坪 )`}
                </Td>
              </Row>
              <Row
                className="w-full md:w-1/2"
                label={
                  <Th left={true} column={2}>
                    有効敷地面積
                  </Th>
                }
              >
                <Td column={2}>
                  {`${cammedFormat(Number(property.effective_area ?? 0), 2)}㎡ ( ${cammedFormat(
                    Number(property.effective_area_tsubo ?? 0),
                    2
                  )}坪 )`}
                </Td>
              </Row>
            </div>
            <div className="flex flex-wrap">
              <Row
                className="w-full md:w-1/2"
                label={
                  <Th left={true} column={2}>
                    {property.property_type == '土地' && '計画'}延床面積
                  </Th>
                }
              >
                <Td column={2}>
                  {`${cammedFormat(Number(property.total_floor_area ?? 0), 2)}㎡ ( ${cammedFormat(
                    Number(property.total_floor_area_tsubo ?? 0),
                    2
                  )}坪 )`}
                </Td>
              </Row>
              <Row
                className="w-full md:w-1/2"
                label={
                  <Th left={true} column={2}>
                    <div className="text-left">
                      {property.property_type == '土地' && '計画'}建物賃貸可能面積（専有面積）
                    </div>
                  </Th>
                }
              >
                <Td column={2}>
                  {`${cammedFormat(Number(property.occupied_area ?? 0), 2)}㎡ ( ${cammedFormat(
                    Number(property.occupied_area_tsubo ?? 0),
                    2
                  )}坪 )`}
                </Td>
              </Row>
            </div>
            {property.property_type == '土地＋建物（区分）' && (
              <Row label={<Th left={true}>区分の場所</Th>}>
                <Td>{property.location_of_division}</Td>
              </Row>
            )}
            <Row label={<Th left={true}>交通機関</Th>}>
              <Td>
                {property.transportations_display.map((transportation, index) => (
                  <div key={index} className={['w-full', index > 0 && 'mt-2'].join(' ')}>
                    {transportation}
                  </div>
                ))}
              </Td>
            </Row>
            <Row label={<Th left={true}>交通機関（IC）</Th>}>
              <Td>
                {property.road_transports_display.map((road_transport, index) => (
                  <div key={index} className={['w-full', index > 0 && 'mt-2'].join(' ')}>
                    {road_transport}
                  </div>
                ))}
              </Td>
            </Row>

            <Row label={<Th left={true}>道路</Th>}>
              <Td>
                {road_access_display.map((road_access, index) => (
                  <div key={index} className={['w-full', index > 0 && 'mt-2'].join(' ')}>
                    {road_access}
                  </div>
                ))}
              </Td>
            </Row>
            <RowAreaInfo label={<Th left={true}>法令制限</Th>}>
              <TdAreaInfo>
                <div className="flex flex-wrap w-full">
                  <RowAreaInfo className="w-full flex" label={<Th left={true}>区域区分</Th>}>
                    <TdAreaInfoData>
                      {property.area_info_hands?.length &&
                      property.area_info_hands.some((area) => area.zoning_area)
                        ? property.area_info_hands.map((area) => area.zoning_area)
                        : ''}
                    </TdAreaInfoData>
                  </RowAreaInfo>
                </div>
                <div className="flex flex-wrap w-full">
                  <RowAreaInfo className="w-full flex" label={<Th left={true}>用途地域</Th>}>
                    <TdAreaInfoData>
                      {property.area_info_hands?.length &&
                      property.area_info_hands.some((area) => area.youto_chiiki_hand)
                        ? property.area_info_hands.map((area) => area.youto_chiiki_hand).join(' / ')
                        : ''}
                    </TdAreaInfoData>
                  </RowAreaInfo>
                </div>
                <div className="flex flex-wrap w-full">
                  <RowAreaInfo
                    className="flex flex-wrap w-full md:w-1/2"
                    label={
                      <Th left={true} column={2}>
                        防火指定
                      </Th>
                    }
                  >
                    <TdAreaInfoData column={2}>
                      {property.area_info_hands?.length &&
                      property.area_info_hands.some((area) => area.bouka_chiiki_hand)
                        ? property.area_info_hands.map((area) => area.bouka_chiiki_hand).join(' / ')
                        : ''}
                    </TdAreaInfoData>
                  </RowAreaInfo>
                  <RowAreaInfo
                    className="flex flex-wrap w-full md:w-1/2"
                    label={
                      <Th left={true} column={2}>
                        高度地区
                      </Th>
                    }
                  >
                    <TdAreaInfoData column={2}>
                      {property.area_info_hands?.length &&
                      property.area_info_hands.some((area) => area.koudo_chiku_hand)
                        ? property.area_info_hands.map((area) => area.koudo_chiku_hand).join(' / ')
                        : ''}
                    </TdAreaInfoData>
                  </RowAreaInfo>
                </div>
                <div className="flex flex-wrap w-full">
                  <RowAreaInfo className="w-full flex" label={<Th left={true}>日影規制</Th>}>
                    <TdAreaInfoData>
                      {property.area_info_hands?.length &&
                      property.area_info_hands.some((area) => area.shadow_area_hand)
                        ? property.area_info_hands.map((area) => area.shadow_area_hand).join(' / ')
                        : ''}
                    </TdAreaInfoData>
                  </RowAreaInfo>
                </div>
                <div className="flex flex-wrap w-full">
                  <RowAreaInfo
                    className="flex flex-wrap w-full md:w-1/2"
                    label={
                      <Th left={true} column={2}>
                        建ぺい率
                      </Th>
                    }
                  >
                    <TdAreaInfoData column={2}>
                      {property.area_info_hands?.length &&
                      property.area_info_hands.some((area) => area.building_coverage_ratio_hand)
                        ? property.area_info_hands
                            .map((area) => {
                              const ratio = area?.building_coverage_ratio_hand ?? ''
                              return ratio ? `${ratio}%` : ''
                            })
                            .join(' / ')
                        : ''}
                    </TdAreaInfoData>
                  </RowAreaInfo>
                  <RowAreaInfo
                    className="flex flex-wrap w-full md:w-1/2"
                    label={
                      <Th left={true} column={2}>
                        容積率
                      </Th>
                    }
                  >
                    <TdAreaInfoData column={2}>
                      {property.area_info_hands?.length &&
                      property.area_info_hands.some((area) => area.floor_area_ratio_hand)
                        ? property.area_info_hands
                            .map((area) => {
                              const ratio = area?.floor_area_ratio_hand ?? ''
                              return ratio ? `${ratio}%` : ''
                            })
                            .join(' / ')
                        : ''}
                    </TdAreaInfoData>
                  </RowAreaInfo>
                </div>
                <div className="flex flex-wrap w-full">
                  <RowAreaInfo className="w-full flex" label={<Th left={true}>その他</Th>}>
                    <TdAreaInfoData>
                      {property.area_info_hands?.length
                        ? property.area_info_hands.map((area) => area.other_info)
                        : ''}
                    </TdAreaInfoData>
                  </RowAreaInfo>
                </div>
              </TdAreaInfo>
            </RowAreaInfo>
          </div>
        </Table>
        <div id="container"></div>
        {pageType === 'market_datum_report' && is_enable_market_datum_report ? (
          <div id="map_report" className="lg:flex mb-4 flex-col">
            <input value="" type="hidden" name="property[shape]" id="property_shape"></input>
            <input type="hidden" value={property?.lat} name="property[lat]" id="property_lat" />
            <input type="hidden" value={property?.lng} name="property[lng]" id="property_lng" />
            <div id="container">
              {!forceMarketDatumReportMap && (
                <AnchorButton
                  className="text-primary-font mt-[10px]"
                  prefix={<MapIcon fontSize="small" />}
                  outline
                  size="small"
                  variant="primary"
                  onClick={() => setIsMapVisible(!isMapVisible)}
                >
                  <span className="hidden md:inline">
                    {isMapVisible ? '地図 を非表示' : '地図 を表示'}
                  </span>
                </AnchorButton>
              )}
            </div>
            <div className="flex my-4 w-full">
              <PropetyMarketDatumReport
                property={property}
                mapRef={onLoadComplete ? mapRef : null}
                current_user={current_user}
                authenticity_token={authenticity_token}
                market_datum_report_layouts={market_datum_report_layouts}
                market_datum_report_column_names={market_datum_report_column_names}
                prefecture_options={prefecture_options}
                transportation_options={transportation_options}
                station_options={station_options}
                floor_plan_options={floor_plan_options}
                contractor_options={contractor_options}
                room_type_options={room_type_options}
                is_properties={true}
                display_items={display_items}
                setForceMarketDatumReportMap={setForceMarketDatumReportMap}
              >
                {isLoaded && (
                  <>
                    <GoogleMapViewer
                      polygons_api_base_url={polygons_api_base_url}
                      csrfToken={csrfToken.content}
                      mode="market_datum_report"
                      property_id={Number(property?.id)}
                      lat={property.address_geocoding[0]}
                      lng={property.address_geocoding[1]}
                      areaShape={JSON.stringify(shape)}
                      existdAreaValue={
                        property?.area_info
                          ? unit_m2(JSON.parse(property?.area_info)?.['site']?.['area'])
                          : ''
                      }
                      setWideAreaZoom={setWideAreaZoom}
                      setNarrowAreaZoom={setNarrowAreaZoom}
                      setShowYoutoInfo={setShowYoutoInfo}
                      setYoutoInfo={(youtoInfo) => {
                        const newYoutoInfo = { ...youtoInfo }
                        setYoutoInfo(newYoutoInfo)
                      }}
                      setOnLoadComplete={setOnLoadComplete}
                      ref={mapRef}
                      current_user={current_user}
                      style={{
                        display: isMapVisible || forceMarketDatumReportMap ? 'block' : 'none',
                        ...(detectLargeScreen()
                          ? { width: '600px', height: '600px' }
                          : { aspectRatio: '1' }),
                      }}
                      display_items={display_items}
                    />
                  </>
                )}
              </PropetyMarketDatumReport>
            </div>
          </div>
        ) : (
          <div className="mt-4">
            {isLoaded && (
              <GoogleMapViewer
                polygons_api_base_url={polygons_api_base_url}
                csrfToken={csrfToken.content}
                mode="property"
                property_id={Number(property?.id)}
                lat={property.address_geocoding[0]}
                lng={property.address_geocoding[1]}
                areaShape={JSON.stringify(shape)}
                existdAreaValue={
                  property?.area_info
                    ? unit_m2(JSON.parse(property?.area_info)?.['site']?.['area'])
                    : ''
                }
                setWideAreaZoom={setWideAreaZoom}
                setNarrowAreaZoom={setNarrowAreaZoom}
                setShowYoutoInfo={setShowYoutoInfo}
                setYoutoInfo={(youtoInfo) => {
                  const newYoutoInfo = { ...youtoInfo }
                  setYoutoInfo(newYoutoInfo)
                }}
                setOnLoadComplete={setOnLoadComplete}
                ref={mapRef}
                current_user={current_user}
                display_items={display_items}
              />
            )}
            <input value="" type="hidden" name="property[shape]" id="property_shape"></input>
            <input type="hidden" value={property?.lat} name="property[lat]" id="property_lat" />
            <input type="hidden" value={property?.lng} name="property[lng]" id="property_lng" />
          </div>
        )}
      </div>

      <div ref={ref}>
        {pageType === 'proposals' && (
          <PropetyProposals
            property={property}
            source_display={source_display}
            user={current_user}
            wideAreaZoom={wideAreaZoom}
            narrowAreaZoom={narrowAreaZoom}
          />
        )}
        {pageType === 'property_details' && is_show_detail && (
          <PropetyDetails
            property={property}
            source_display={source_display}
            excel_file={excel_file}
            is_enable_edit_detail={is_enable_edit_detail}
            display_item_data={display_items}
            current_user={current_user}
          />
        )}
        {pageType === 'attachments' && is_enable_attachments && (
          <PropetyAttachments
            property={property}
            user={current_user}
            authenticity_token={authenticity_token}
            attached_files={attached_files ?? []}
            error_messages={error_messages}
          />
        )}
        {pageType === 'public_files' && is_enable_public_files && (
          <PublicFileDetails
            property={property}
            public_file_details={public_file_details}
            user={current_user}
            authenticity_token={authenticity_token}
          />
        )}
        {pageType === 'property_documents' && is_enable_property_documents && (
          <PropertyDocuments
            property={property}
            property_documents={property_documents}
            user={current_user}
            authenticity_token={authenticity_token}
          />
        )}
        {pageType === 'surroundings' && is_enable_surroundings && (
          <PropetySurroundings
            property={property}
            hazard_links={hazard_links}
            mapRef={onLoadComplete ? mapRef : null}
            user={current_user}
            authenticity_token={authenticity_token}
            prefecture_options={prefecture_options}
            transportation_options={transportation_options}
            station_options={station_options}
            floor_plan_options={floor_plan_options}
            contractor_options={contractor_options}
            room_type_options={room_type_options}
            display_items={display_items}
          />
        )}
        {pageType === 'touki' &&
          (current_user.company.touki_enabled === true || current_user.role === 'system_admin') && (
            <PropertyTouki property={property} user={current_user} />
          )}
        {pageType === 'volume_check' && is_enable_volume_check && (
          <PropetyVolume
            property={property}
            current_user={current_user}
            result_contents={result_contents}
            pagy={pagy}
            is_enable_edit_detail={is_enable_edit_detail}
          />
        )}
      </div>
      {/* kintone連携の確認ダイアログ */}
      <ConfirmDialog open={dialogOpen} onClose={onCloseDialog}>
        <div className="text-black-base text-sm flex flex-col text-center gap-y-3">
          <p>kintoneへ物件データの登録を行います。</p>
          <p>よろしいですか？</p>
        </div>
      </ConfirmDialog>

      <Modal open={tagChangeModal} onClose={onTagChangeModalClose}>
        <div className="px-6 py-6 leading-10">
          タグを{property?.['tag']?.name && `${property?.['tag']?.name}から`}
          {tag?.name}変更します。よろしいですか？
        </div>

        <form
          action={window.location.href}
          acceptCharset="UTF-8"
          method="post"
          encType="multipart/form-data"
          ref={tagChangeFormRef}
        >
          <input
            type="hidden"
            name="authenticity_token"
            value={document.querySelector<HTMLMetaElement>('meta[name=csrf-token]')?.content}
          />
          <input type="hidden" name="_method" value={'patch'} />
          <input type="hidden" name="property[tag_id]" value={tag?.id} />
          <input
            type="hidden"
            name="redirect_to"
            value={window.location.pathname + window.location.search}
          />
        </form>

        <AnchorButton
          className="text-white md:w-[120px] w-full h-[36px]"
          size="small"
          variant="primary"
          type="submit"
          onClick={() => {
            tagChangeFormRef.current?.submit()
          }}
        >
          はい
        </AnchorButton>
        <Button
          className="text-primary-deep border-white md:w-[120px] w-full h-[36px]"
          outline
          size="small"
          variant="primary"
          onClick={onTagChangeModalClose}
        >
          閉じる
        </Button>
      </Modal>
    </Base>
  )
}

export default PropertiesDetailPage

const Status: React.FC<{
  children?: React.ReactNode
  className?: string
  outlined?: boolean
  style?: React.CSSProperties
}> = ({ children, className, outlined = false, style = {} }) => (
  <div
    style={style}
    className={[
      'inline-flex text-sm font-bold px-4 py-[3px] rounded-xl border-[2px]',
      !outlined && 'border-transparent',
      className,
    ].join(' ')}
  >
    {children}
  </div>
)
